import { createApp } from 'vue'
import Vue3Lottie from 'vue3-lottie'
import App from './App.vue'
import router from './router'
import store from './store'
import BaseInput from "@/components/BaseInput.vue"
import BaseFileInput from "@/components/BaseFileInput.vue"
import BaseLoader from '@/components/BaseLoader.vue'
import './styles/normalize.scss'
import './styles/ui-elements.scss'
import './styles/helper-classes.scss'
import './styles/vars.scss'
import './styles/typography.scss'
import './styles/typography.scss'

const app = createApp(App);

app
  .component('BaseInput', BaseInput)
  .component('BaseFileInput', BaseFileInput)
  .component('BaseLoader', BaseLoader)
  .use(Vue3Lottie)
  .use(store)
  .use(router)

app.mount('#app')