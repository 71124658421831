import { createStore } from 'vuex'
import axios from 'axios'
import api from './api'
import result from './result.json'

export default createStore({
  state: {
    result: [],
    csvResult: '',
    reqStatus: 'idle',
    form: {
      "domain": ""
    },
    filters: {
      "keywords": [],
      "negativeKeywords": [],
      "viewCountMin": 1000,
      "durationMaxSeconds": 10000,
      "monthsInterval": 1
    },
    searchName: '',
    errorMsg: ''
  },
  mutations: {
    SET_STATE (state, {key, value}) {
      state[key] = value
    },
    SET_REQ_STATUS (state, status) {
      if (status === 'success') {
        state.reqStatus = 'success'
        setTimeout(() => {
          state.reqStatus = 'idle'
        }, 1000)
      } else {
        state.reqStatus = status
      }
    },
    SET_FORM_FIELD (state, {name, value}) {
      state.form[name] = value
    },
    SET_FILTER (state, {name, value}) {
      switch (name) {
        case 'keywords':
        case 'negativeKeywords':
          state.filters[name] = value.trim().split(',')
          break
        default:
          state.filters[name] = value
      }
    },
    RESET_FORM (state) {
      state.form.domain = ''
    }
  },
  actions: {
    async get_filters ({commit}) {
      try {
        commit('SET_REQ_STATUS', 'fetching')
        const { data } = await axios.get(api.filters)
        commit('SET_STATE', {key: 'filters', value: data})
        commit('SET_REQ_STATUS', 'idle')
      } catch (err) {
        commit('SET_REQ_STATUS', 'failed')
      }
    },
    async save_filters ({state, commit}) {
      try {
        commit('SET_REQ_STATUS', 'fetching')
        await axios.put(api.filters, state.filters)
        commit('SET_REQ_STATUS', 'idle')
      } catch (err) {
        commit('SET_REQ_STATUS', 'failed')
      }
    },
    async send_data ({state, commit}) {
      try {
        commit('SET_REQ_STATUS', 'sending')
        commit('SET_STATE', {key: 'result', value: []})
        commit('SET_STATE', {key: 'csvResult', value: ''})
        const { data } = await axios.post(api.scrape, {...state.form, ...state.filters})
        commit('SET_STATE', {key: 'searchName', value: state.form.domain})
        commit('SET_STATE', {key: 'result', value: data})
        commit('SET_REQ_STATUS', 'idle')
      } catch (err) {
        commit('SET_REQ_STATUS', 'failed')
        commit('SET_STATE', {key: 'errorMsg', value: err.response?.data})
      }
    },
    async send_data_csv ({state, commit}, {formData, clbk = () => {}}) {
      try {
        commit('SET_REQ_STATUS', 'sending')
        commit('SET_STATE', {key: 'result', value: []})
        commit('SET_STATE', {key: 'csvResult', value: ''})
        Object.keys(state.filters)
          .forEach(key => {
            formData.append(key, state.filters[key])
          })
        const { data } = await axios.post(`${api.scrape}/csv`, formData)
        commit('SET_STATE', {key: 'result', value: []})
        commit('SET_STATE', {key: 'csvResult', value: data})
        commit('SET_REQ_STATUS', 'success')
        clbk();
      } catch (err) {
        commit('SET_REQ_STATUS', 'failed')
        commit('SET_STATE', {key: 'errorMsg', value: err.response?.data})
      }
    },
  },
  modules: {
  }
})
