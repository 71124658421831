<template>
  <div class="base-file-input pointer relative">
    <label v-show="label"
           :cy="`label-${name}`"
           class="pointer wh100 flex-center text-center"
           :class="{'has-file': fileName}"
           :for="id || name">
      {{ fileName || label }}
    </label>
    <input v-if="isInput"
           type="file"
           class="w100"
           :cy="name"
           :id="id || name"
           :placeholder="placeholder || name"
           @input="handleFile" />
    <img v-show="fileName"
         class="absolute pointer"
         @click="handleClear"
         src="../assets/close.svg"
         alt="close" />
  </div>
</template>

<script setup>
  import {ref} from "vue";

  const props = defineProps({
    name: String,
    label: String,
    id: String,
    placeholder: String,
  })

  const emits = defineEmits(['upload', 'clear'])
  const isInput = ref(true)
  const fileName = ref('')
  defineExpose({ handleClear });

  function handleFile ({target: { files }}) {
    fileName.value = files[0]?.name
    // when we click Cancel on the select file modal, the file is cleared by the browser API, and we need to emit that
    fileName.value
        ? emits('upload', {name: props.name, file: files[0]})
        : emits('clear', {name: props.name})
  }

  function handleClear () {
    fileName.value = ''
    // remove and restate the input tag in order to be able to upload again
    isInput.value = false
    setTimeout(() => {
      isInput.value = true
    }, 200)
    emits('clear', {name: props.name})
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .base-file-input {
    border-radius: 4px;
    border: 0.5px solid black;
    img {
      top: 12px;
      right: 12px;
      opacity: .6;
      transition: transform 0.2s ease-in-out;
      &:hover {
        transform: scale(1.2);
      }
    }
    label {
      color: $tech-gray;
      font-size: 1.4rem;
      font-weight: 700;
      &.has-file {
        background-color: $green;
        color: white;
      }
      &:hover {
        background-color: rgba($tech-gray, 0.16);
      }
    }
    input {
      visibility: hidden;
      height: 0.1px;
      width: 0;
    }
  }
</style>