<template>
  <div class="home-page">
    <h1 class="text-center">Pulse Engine</h1>

    <p>Enter a domain or upload a CSV</p>

    <div class="search-section flex flex-between align-center">
      <BaseInput name="domain"
                 placeholder="https://example.com"
                 :disabled="isCSVFile"
                 :value="form.domain"
                 @input="handleInput" />
      <span>or</span>
      <BaseFileInput name="csvfile"
                     ref="baseFileRef"
                     label="Upload CSV (with 'domain' column)"
                     @upload="handleFile"
                     @clear="handleClearFile" />
      <button class="purple-btn"
              :disabled="submitDisabled"
              cy="submit"
              @click="handleSubmit">Submit</button>
    </div>

    <div>
      <div v-show="reqStatus === 'sending'">
        <BaseLoader />
        <p class="text-center m0">Hang tight, we are scraping the data for you!</p>
      </div>

      <p v-show="reqStatus === 'failed'" class="text-center m-t-0">
        There was a server error, please try again.
        <br />
        {{ errorMsg }}
      </p>
    </div>

    <div v-if="result.length"
         cy="results"
         class="result-section m-b-24">
      <h2>Results for {{ searchName }}</h2>
      <ul>
        <li v-for="({name, value}, index) in result"
            :key="index">
          <p>{{ name }}: {{ value }}</p>
        </li>
      </ul>
    </div>

    <div v-if="csvResult" cy="csv-results">
      <h2>Results for CSV</h2>
      <p>The csv file was sent to email.</p>
    </div>

    <section class="filters-section"
             cy="filters"
             :class="{active: isFilters}">
      <div class="header inline-flex align-center pointer"
           @click="toggleFilters">
        <h2>Filters</h2>
        <img src="../assets/arrow.svg" alt="filter arrow" />
      </div>

      <BaseInput name="keywords"
                 placeholder="keywords included separated by a comma"
                 label="Keywords that if included in the title of videos, we will scrap"
                 :value="filters.keywords"
                 @input="handleInputFilter" />

      <BaseInput name="negativeKeywords"
                 placeholder="keywords not included separated by a comma"
                 label="Keywords that if included in the title of videos, we will not scrap"
                 :value="filters.negativeKeywords"
                 @input="handleInputFilter" />

      <BaseInput name="monthsInterval"
                 placeholder="0"
                 label="Date threshold (in months). Videos before then will not be considered"
                 :value="filters.monthsInterval"
                 @input="handleInputFilter">
        <span>months</span>
      </BaseInput>

      <BaseInput name="viewCountMin"
                 placeholder="0"
                 label="View count threshold (in thousands). Videos with less than this will not be considered"
                 :value="filters.viewCountMin"
                 @input="handleInputFilter">
        <span>,000</span>
      </BaseInput>

      <div class="actions flex">
        <button class="purple-btn"
                cy="save-filters"
                @click="handleSaveFilters">Save settings</button>
      </div>
    </section>
  </div>
</template>

<script setup>
  import {computed, ref} from "vue";
  import {useStore} from "vuex";

  const { state, dispatch, commit } = useStore()
  dispatch('get_filters')
  const isFilters = ref(false)
  const reqStatus = computed(() => state.reqStatus)
  const errorMsg = computed(() => state.errorMsg)
  const form = computed(() => state.form)
  const filters = computed(() => state.filters)
  const result = computed(() => state.result)
  const csvResult = computed(() => state.csvResult)
  const searchName = computed(() => state.searchName)
  const isCSVFile = ref(false)
  const submitDisabled = computed(() => reqStatus.value === 'sending' || (!form.value.domain && !isCSVFile.value))
  let formData = new FormData()
  const baseFileRef = ref(null)

  function handleInputFilter ({name, value}) {
    commit('SET_FILTER', {name, value})
  }

  function handleSaveFilters () {
    dispatch('save_filters')
  }

  function handleFile ({name, file}) {
    isCSVFile.value = true
    formData = new FormData()
    formData.append(name, file)
    commit('SET_FORM_FIELD', {name: 'domain', value: ''})
  }

  function handleClearFile () {
    isCSVFile.value = false
  }

  function toggleFilters () {
    isFilters.value = !isFilters.value
  }

  function handleInput ({name, value}) {
    commit('SET_FORM_FIELD', {name, value})
  }

  function handleSubmit () {
    dispatch(isCSVFile.value ? 'send_data_csv' : 'send_data', {formData, clbk: clearFile})
  }

  function clearFile () {
    baseFileRef.value.handleClear()
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .home-page {
    @media only screen and (min-width: 0) {
      max-width: 1176px;
      margin: 0 auto 200px;
      h1 {
        margin-top: 43px;
      }

      p {
        color: $tech-gray;
        font-weight: 700;
      }

      h2 {
        margin-right: 12px;
        color: $tech-gray;
        font-weight: 700;
      }

      .search-section {
        margin: 43px auto;
        .base-input {
          width: 424px;
        }
        span {
          display: inline-block;
          margin: 0 16px;
          color: $tech-gray;
        }
        .base-file-input {
          width: 408px;
          height: 48px;
        }
        .purple-btn {
          width: 224px;
          height: 48px;
          margin-left: 16px;
        }
      }

      .result-section {
        h2 {
          font-size: 1.8rem;
          font-weight: 700;
          color: $tech-gray;
        }
        ul {
          margin-top: 20px;
        }
        p {
          font-weight: 400;
          margin: 0 0 6px;
        }
      }

      .filters-section {
        height: 24px;
        transition: height 0.2s ease-in-out;
        overflow: hidden;
        .header {
          h2 + img {
            transform-origin: center;
            transform: rotate(180deg);
            transition: transform 0.2s ease-in-out;
          }
        }

        .base-input {
          margin-top: 43px;
          display: block;
          input {
            width: 510px;
          }
          span {
            margin-left: 5px;
            color: $tech-gray;
          }
          &:nth-child(4) {
            input {
              width: 50px;
            }
          }

          &:nth-child(5) {
            input {
              width: 70px;
            }
          }
          &:nth-child(4),
          &:nth-child(5) {
            input {
              width: 50px;
              padding: 15px 0;
              text-align: center;
            }
          }
        }

        .actions {
          margin-top: 19px;
          .purple-btn {
            padding: 15px 60px;
          }
        }

        &.active {
          height: 620px;
          h2 + img {
            transform: rotate(0deg);
            transform-origin: center;
          }
        }
      }
    }
  }
</style>
