<template>
  <div class="base-input">
    <label v-show="label"
           :for="id || name">
      {{ label }}
    </label>
    <input type="text"
           class="w100"
           :id="id || name"
           :value="value"
           :cy="name"
           :placeholder="placeholder || name"
           :disabled="disabled"
           @input="handleInput" />
    <slot />
  </div>
</template>

<script setup>
  const props = defineProps({
    name: String,
    label: String,
    id: String,
    value: [String, Number],
    placeholder: String,
    disabled: Boolean,
  })

  const emits = defineEmits(['input'])

  function handleInput ({target}) {
    emits('input', {name: props.name, value: target.value})
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .base-input {
    label {
      margin-bottom: 19px;
      display: block;
      color: $tech-gray;
      font-weight: 400;
    }
    input {
      padding: 15px 30px;
      font-size: 1.4rem;
      font-weight: 700;
      border-radius: 4px;
      border: 0.5px solid black;
      &::placeholder {
        color: $gray;
      }
    }
  }
</style>