<template>
  <header class="main-header">
    <nav>
      <img src="./assets/logo.svg" alt="riverside logo" />
    </nav>
  </header>

  <div class="main-content">
    <router-view/>
  </div>
</template>

<script setup>
</script>

<style lang="scss">
  html,
  body {
    margin: 0;
    padding: 0;
    font-size: 62.5%;
  }

  * {
    box-sizing: border-box;
  }

  .main-header {
    padding-top: 29px;
  }

  .main-header,
  .main-content {
    padding-left: 43px;
    padding-right: 43px;
  }
</style>